import slick from 'slick-carousel';
import 'magnific-popup';

jQuery(function ($) {

  // $('.suit__options > li').eq(2).addClass('is_select');

  // 初回アクセス時の画像クリック
  $('.article__center__upper span').on('click', function () {
    $('.article__firstAccess').hide();
    $('.article__center__upper').hide();
    $('.article__center__ttl').hide();
  });

  // 左メニュー切り替え
  $('.suit__colorBox__ttl').on('click', function () {
    $('.suit__colorBox').removeClass('is_select');
    $(this).parent().addClass('is_select');
    $('.suit__options > li').removeClass('is_select');
    $('.suit__colorBox__options > li').removeClass('is_select');
    $('.article__center').removeClass('is_center');
    $('.article__center').removeClass('is_option');
    $('.article__right').hide();
    $('.header__lettering').removeClass('is_show');
    $('.header__ventilation').removeClass('is_show');
    $('.header__lettering').removeClass('is_show');
    $('.header__others').removeClass('is_show');
    if ($(this).parent().hasClass('is_coloring')) {
      $('.article__right').show();
    } else if ($(this).parent().hasClass('is_traps')) {
      $('.article__center').addClass('is_center');
    } else if ($(this).parent().hasClass('is_option')) {
      $('.article__center').addClass('is_option');
      $('.suit__options > li').eq(0).addClass('is_select');
      $('.suit__colorBox__options > li').eq(0).addClass('is_select');
      if ($('.suit__colorBox__options__item.is_lettering').hasClass('is_select')) {
        $('.suit__colorList.is_lettering').addClass('is_show');
        $('.header__lettering').addClass('is_show');
      } else {
        $('.suit__colorList.is_lettering').removeClass('is_show');
        $('.header__lettering').removeClass('is_show');
      }
    }
  });

  // 左メニューOPTIONS切り替え
  $('.suit__colorBox__options__item').on('click', function () {
    $('.suit__colorBox__options__item').removeClass('is_select');
    $(this).addClass('is_select');
    var index = $('li.suit__colorBox__options__item').index(this);
    $('.suit__options > li').removeClass('is_select');
    $('.suit__options > li').eq(index).addClass('is_select');
    if ($('.suit__colorBox__options__item.is_lettering').hasClass('is_select')) {
      $('.suit__colorList.is_lettering').addClass('is_show');
      $('.header__lettering').addClass('is_show');
    } else {
      $('.suit__colorList.is_lettering').removeClass('is_show');
      $('.header__lettering').removeClass('is_show');
    }
    if ($('.suit__colorBox__options__item.is_ventilation').hasClass('is_select')) {
      $('.header__ventilation').addClass('is_show');
    } else {
      $('.header__ventilation').removeClass('is_show');
    }
    if ($('.suit__colorBox__options__item.is_others').hasClass('is_select')) {
      $('.header__others').addClass('is_show');
    } else {
      $('.header__others').removeClass('is_show');
    }
  });

  // フッタースーツに戻るクリック時のポップアップ
  $('.popup__return').on('click', function () {
    $('.popupBox__return').show();
  });

  // カラーテンプレートのSELECT選択時ポップアップ
  $('.hugeModal__btn').on('click', function () {
    $('.popupBox__templateSelect').show();
  });

  // カラーテンプレートのスーツ選択に戻る選択時ポップアップ
  $('.hugeModal__return').on('click', function () {
    $('.popupBox__templateBack').show();
  });

  var saveList__index;

  // カラーリングポップアップの新規保存クリック時ポップアップ
  $('li.hugeModal__saveList__item a').on('click', function () {
    saveList__index = $(this).parent().index('li.hugeModal__saveList__item');
    $('.popupBox__newSave').show();
  });

  // カラーリングポップアップ新しくカラーリング情報を保存しますか？ OK時
  $('.color__change').on('click', function () {
    $('.popupBox__newSave').hide();
    $('.hugeModal__saveList__item').eq(saveList__index).html('<div class="hugeModal__saveList__imgBox"><img src="img/img/img_suit07.png" alt=""></div><div class="hugeModal__saveList__bottom"><a class="hugeModal__saveList__replace"><img src="img/ico/ico_return01.svg" alt="">この内容に切り替える<br class="sp"><span class="pc"> / </span>Replace </a><a class="hugeModal__saveList__delete"><img src="img/ico/ico_delete01.svg" alt=""></a></div>');

    // B3. 一時保存/プレビュー画面：この内容に切り替えるを選択した場合
    $('.hugeModal__saveList__replace').on('click', function () {
      $('.popupBox__checkLettringReplace').show();
    });

    // B3. 一時保存/プレビュー画面：ゴミ箱アイコンを選択した場合
    $('.hugeModal__saveList__delete').on('click', function () {
      $('.popupBox__checkLettringDelete').show();
    });
  });

  // カ選択したカラーリングに切り替えます。現在編集中のカラーリング情報を保存しますか？ Save押下時
  $('.color__change__save').on('click', function () {
    $('.popupBox__checkLettringReplace').hide();
  });

  // カラーリングポップアップ新しくカラーリング情報を保存しますか？ OK時
  $('.color__delete').on('click', function () {
    $('.popupBox__wrap').hide();
    $('.hugeModal__saveList__item').eq(saveList__index).html('<a class="hugeModal__saveList__newSave"><div class="hugeModal__saveList__newBox"><img src="img/ico/ico_plus01.svg" alt=""><h3 class="hugeModal__saveList__ttl">新規保存<span>New save</span></h3></div></a>');

    // カラーリングポップアップの新規保存クリック時ポップアップ
    $('li.hugeModal__saveList__item a').on('click', function () {
      saveList__index = $(this).parent().index('li.hugeModal__saveList__item');
      $('.popupBox__newSave').show();
    });
  });

  // 復帰用URLの発行の送信ボタン押下時 表示変更
  $('.hugeModal__sendemail').submit(function () {
    var email = $('input[name="email"]').val();
    var agree = $('input[name="agree"]');
    if (!email) {
      $('.hugeModal__inputBox__error').addClass('is_show');
    } else {
      $('.hugeModal__inputBox__error').removeClass('is_show');
    }
    console.log(agree);
    if (agree.prop("checked")) {
      $('.hugeModal__agreeBox__error').removeClass('is_show');
    } else {
      $('.hugeModal__agreeBox__error').addClass('is_show');
    }
    if (!email || !agree.prop("checked")) {
      return false;
    }
    $('.hugeModal__ttl').html('送信完了 / COMPLETE');
    $('.hugeModal__txt').html('メールボックスを確認してください。/ Please check your mailbox.<br><br>');
    $('.hugeModal__sendemail').empty();
    $('.hugeModal__sendemail').append('<div class="hugeModal__btnBox"><a class="hugeModal__hugeBtn hugeModal__close is_btn_hover">カスタマイズを続ける<br class="sp"><span class="pc"> / </span>Continue to customize</a><a href="" class="hugeModal__openInquiry hugeModal__hugeBtn is_btn_hover">お問い合わせに進む<br class="sp"><span class="pc"> / </span>Proceed to inquiry</a></div>');
    return false;
  });

  $(document).on('click', '.hugeModal__openInquiry', function (e) {
    e.preventDefault();
    $.magnificPopup.close();
    $.magnificPopup.open({
        items: {src: '#popup-personal-information'},
        type: 'inline',
        fixedContentPos: 'true',
        fixedBgPos: 'true',
        enableEscapeKey: false
    }, 0);

    $('.hugeModal__sendemail').empty();
    $('.hugeModal__sendemail').append('<div class="hugeModal__inputBox"><input type="email" name="email" placeholder="Email"><span class="hugeModal__inputBox__error">メールアドレスを入力してください / Please enter your E-mail Address</span></div><div class="hugeModal__agreeBox"><label><input type="checkbox" name="agree"><span class="hugeModal__agreeBox__right"><a href="https://www.rs-taichi.com/privacy/" target="_blank">プライバシーポリシー</a>に同意します。 /   I accept the <a href="https://www.rs-taichi.com/privacy/?lang=en" target="_blank">privacy policy.</a><span class="hugeModal__agreeBox__error">プライバシーポリシーに同意してください / Please agree to the privacy policy</span></span></label></div><div class="hugeModal__submitBox"><input type="submit" value="送信 / SUBMIT"></div>');
  });

  // お客様情報入力画面 入力内容の確認 / CONFIRM
  $('.contactModal__formBox__size').on('click', function () {
    $('.popupBox__sizeBox').show();
  });

  //
  $('.contactModal__confirm').submit(function () {
    var fullname = $('input[name="fullname"]');
    var email = $('input[name="email"]');
    var email_confirm = $('input[name="email_confirm"]');
    var phone = $('input[name="phone"]');
    var state = false;
    $('.custom-options span').each(function() {
      if ($(this).hasClass('selection')) {
        state = true;
      }
    });
    var agree = $('input[name="agree"]');
    if (!fullname.val()) {
      fullname.closest('.contactModal__formBox').addClass('is_error');
    } else {
      fullname.closest('.contactModal__formBox').removeClass('is_error');
    }
    if (!email.val() || !email_confirm.val()) {
      email.closest('.contactModal__formBox').addClass('is_error');
    } else {
      email.closest('.contactModal__formBox').removeClass('is_error');
    }
    if (!phone.val()) {
      phone.closest('.contactModal__formBox').addClass('is_error');
    } else {
      phone.closest('.contactModal__formBox').removeClass('is_error');
    }
    if (state == false) {
      $('.custom-select-wrapper').closest('.contactModal__formBox').addClass('is_error');
    } else {
      $('.custom-select-wrapper').closest('.contactModal__formBox').removeClass('is_error');
    }
    if (agree.prop("checked")) {
      agree.closest('.contactModal__agreeBox').removeClass('is_error');
    } else {
      agree.closest('.contactModal__agreeBox').addClass('is_error');
    }
    if (!fullname.val() || !email.val() || !email_confirm.val() || !phone.val() || state == false) {
      $(".contactModal__box").animate({ scrollTop: 0 }, 800, "swing");
      return false;
    } else if (!agree.prop("checked")) {
      return false;
    }
  });

  $('.contactModal__ttl4.is_parent').on('click', function () {
    $(this).toggleClass('is_open');
    $(this).next().slideToggle();
  });

  // Lettering時の取付け箇所、レタリングチェック
  $('.article__center__btn').on('click', function () {
    if ($('.suit__lettering__select').val() == null) {
      $('.popupBox__checkLettringSelct').show();
    }
    if (!($('.lettering__text').val().match(/[0-9a-zA-Z]/))) {
      $('.popupBox__checkLettringText').show();
    }
  });

  // レタリングオプション画面：取付箇所選択時
  $('.suit__lettering__selectList__item a').on('click', function (e) {
    e.preventDefault();
    $.magnificPopup.close();
  });

  // レタリングオプション画面：取付箇所選択時
  $('.suit__lettering__btn').on('click', function (e) {
    e.preventDefault();
    $.magnificPopup.close();
  });

  // アラートポップアップキャンセル
  $('.cancel__btn').on('click', function () {
    $('.popupBox__wrap').hide();
  });

  $('.hugeModal__imgList__item').on('click', function () {
    $('.hugeModal__imgList__item').removeClass('is_current');
    $(this).addClass('is_current');
  });

  //  modal
  if ($('.popup__modal').length) {
    $('.popup__modal').magnificPopup({
      type: 'inline',
      fixedContentPos: true,
      fixedBgPos: true,
      closeOnBgClick: false,
      enableEscapeKey: false
    });
  }

  $(document).on('click', '.hugeModal__close', function (e) {
    e.preventDefault();
    $.magnificPopup.close();
    $('.hugeModal__sendemail').empty();
    $('.hugeModal__sendemail').append('<div class="hugeModal__inputBox"><input type="email" name="email" placeholder="Email"><span class="hugeModal__inputBox__error">メールアドレスを入力してください / Please enter your E-mail Address</span></div><div class="hugeModal__agreeBox"><label><input type="checkbox" name="agree"><span class="hugeModal__agreeBox__right"><a href="https://www.rs-taichi.com/privacy/" target="_blank">プライバシーポリシー</a>に同意します。 /   I accept the <a href="https://www.rs-taichi.com/privacy/?lang=en" target="_blank">privacy policy.</a><span class="hugeModal__agreeBox__error">プライバシーポリシーに同意してください / Please agree to the privacy policy</span></span></label></div><div class="hugeModal__submitBox"><input type="submit" value="送信 / SUBMIT"></div>');
  });

  // color slide
  $(window).on('load', function () {
    if ('.suit__colorList'.length) {
      if (window.matchMedia('(max-width: 767px)').matches) {
        $('.suit__colorList').slick({
          centerMode: true,
          centerPadding: '20%',
          arrows: false,
          dots: false,
          speed: 1500,
          slidesToShow: 5,
          adaptiveHeight: true,
          autoplay: false,
          variableWidth: true,
        });
      }
    }
  });

});

// T-RAPSを装着していない時、T-RAPS画面のカラーパレットを無効化
$('input[name=radio1]').on('click', function() {
  if ($('input[name=radio1]:eq(0)').prop('checked')) {
    $('.suit__colorBox.is_traps .suit__colorBox__content').addClass('is_hide');
  } else {
    $('.suit__colorBox.is_traps .suit__colorBox__content').removeClass('is_hide');
  }
});

// select ドロップダウン
$(".custom-select").each(function() {
  var classes = $(this).attr("class"),
      id      = $(this).attr("id"),
      name    = $(this).attr("name");
  var template =  '<div class="' + classes + '">';
      template += '<span class="custom-select-trigger">' + $(this).attr("placeholder") + '</span>';
      template += '<div class="custom-options">';
      $(this).find("option").each(function() {
        template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
      });
  template += '</div></div>';

  $(this).wrap('<div class="custom-select-wrapper"></div>');
  $(this).hide();
  $(this).after(template);
});
$(".custom-option:first-of-type").on('hover', function() {
  $(this).parents(".custom-options").addClass("option-hover");
}, function() {
  $(this).parents(".custom-options").removeClass("option-hover");
});
$(".custom-select-trigger").on("click", function() {
  $('html').one('click',function() {
    $(".custom-select").removeClass("opened");
  });
  $(this).parents(".custom-select").toggleClass("opened");
  event.stopPropagation();
});
$(".custom-option").on("click", function () {
  $(this).parents(".custom-select-wrapper").find("select").val($(this).data("value"));
  $(this).parents(".custom-options").find(".custom-option").removeClass("selection");
  $(this).addClass("selection");
  $(this).parents(".custom-select").removeClass("opened");
  $(this).parents(".custom-select").find(".custom-select-trigger").text($(this).text());
});
