$(document).ready(function () {
  var txtBox = $('.top_article__txtBox');
  var slider = $('.top_article__slider__wrap')
  var windowHeight = document.documentElement.clientHeight;
  var txtBoxHeight = txtBox.height();
  var txtBoxCenterPos = (windowHeight / 2) - (txtBoxHeight / 2);
  var txtBoxCurrenPos = txtBox.offset().top;
  var diffPos = txtBoxCenterPos - txtBoxCurrenPos;
  txtBox.css('transform', 'translateY(' + diffPos + 'px)');
  slider.css('transform', 'translateY(' + diffPos + 'px)');
  setTimeout(function () {
    txtBox.css('opacity', '1');
  }, 2000);
  setTimeout(function () {
    $('.top_article').addClass('is_bg_show');
    txtBox.css('transform', 'translateY(0)');
    slider.css('transform', 'translateY(0)');
    slider.css('opacity', 1);
  }, 4000);

  if ($('.top_article__slider').length) {
    var background = 3;
    $('.top_article__slider').roundabout({
      tilt: -4,
      minScale: 0.5,
      minOpacity: 0.5
    });

    if (window.matchMedia('(max-width: 767px)').matches) {
      var sp = '_sp';
    } else {
      var sp = '';
    }

    $('.top_article__slider').on('moveClockwiseThroughBack', function () {
      $('.top_article').removeClass('is_bg_show');
      console.log("moveClockwiseThroughBack")
      background = background + 1
      if (background == 7) {
        background = 3
      }
      $(".top_article").css("background", "url(/img/bg/bg0" + background + sp + ".jpg) no-repeat center center")
      $(".top_article").css("background-size", "cover")
    });
    $('.top_article__slider').on('moveCounterclockwiseThroughBack', function() {
      console.log("moveCounterclockwiseThroughBack")
      background = background - 1
      if (background == 2) {
        background = 6
      }
      $(".top_article").css("background", "url(../img/bg/bg0" + background + sp + ".jpg) no-repeat center center")
      $(".top_article").css("background-size", "cover")
    });
  }
});
